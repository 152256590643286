import React, { useEffect, useState } from "react";
import Axios from "axios";
import Sunburst from "react-d3-zoomable-sunburst";
import "./SunburstData.css";
const styles = {
  width: "800px",
};
// const styles = {
//   container: {
//     flexGrow: 1,
//   },
//   innerContainer: {
//     flexGrow: 1,
//     marginTop: 5,
//     height: "100%",
//   },
//   body: {
//     position: "absolute",
//     width: "97%",
//     display: "flex",
//     margin: 20,
//     marginTop: "15rem",
//   },
//   header: {
//     height: "100%",
//     width: "97%",
//     margin: 20,
//   },
//   headerPadding: {
//     padding: 10,
//     paddingBottom: 5,
//   },
//   paddingDiv: { paddingTop: 300 },
// };

const SunburstData = () => {
  // eslint-disable-next-line no-extend-native
  Array.prototype.groupBy = function (key) {
    return this.reduce(function (groups, item) {
      const val = item[key];
      //   console.log(val);
      groups[val] = groups[val] || [];
      groups[val].push(item);
      //   data["children"].push(item);
      return groups;
    }, {});
  };

  const datam = { name: "Total", children: [] };

  const [data, setData] = useState({
    data: {},
    criteria: "quantity",
  });

  useEffect(() => {
    Axios.post("/sunburstdata").then((response) => {
      //   console.log(response.data);
      const rep = response.data.groupBy("productGroup");
      //   console.log(rep);
      //   console.log(data);
      //   const groups = [
      //     ...new Set(response.data.map((item) => item.productGroup)),
      //   ];

      const groups = Object.keys(rep);
      //   let catNames;
      //   console.log(groups.length);
      let j = 0;
      groups.forEach((el) => {
        // if (el === rep.key) {
        datam["children"].push({
          name: el,
          //   children: [{ catnames: rep[el] }],
          children: [],
        });
        let rep2 = rep[el].groupBy("prodCatName");
        let catNames = Object.keys(rep2);

        let k = 0;
        catNames.forEach((em) => {
          datam["children"][j]["children"].push({
            name: em,
            children: [],
          });
          let rep3 = rep2[em].groupBy("abbreviation");
          let prodNames = Object.keys(rep3);
          prodNames.forEach((et) => {
            // console.log(et);
            // console.log();
            datam["children"][j]["children"][k]["children"].push({
              name: et,
              quantity: rep3[et][0]["quantity"],
              profit: rep3[et][0]["profit"],
            });
          });
          k++;
        });
        // let catNames = [];
        // // let k = rep[el].length;
        // for (let k = 0; k < rep[el].length; k++) {
        //   //   console.log(rep[el][k]["prodCatName"]);
        //   catNames.push(rep[el][k]["prodCatName"]);
        // }
        // // console.log(rep[el].length);
        // // console.log(catNames);
        // catNames = [...new Set(catNames)];
        // console.log(catNames);

        // for (let i = 0; i < catNames.length; i++) {
        //   data["children"][j]["children"].push({
        //     name: catNames[i],
        //     chidren: [],
        //   });
        // }
        j++;

        // console.log(rep[el].groupBy("prodCatName"));
        // data["children"].map((elem) => {
        //   let catNames = [...new Set(elem["children"][0]["catnames"])];
        //   console.log(el);
        //   console.log(elem);
        //   console.log([...new Set(elem["children"][0]["catnames"])]);
        //   let d = [];

        //     // console.log(catNames);
        //     elem["children"].push({ name: catNames[i] });
        //   }
        // });

        //   console.log(d);
        //   catNames.forEach((item) => {
        //     console.log(elem);
        //   });
        //   catNames.map((cname) => {
        //     // console.log(el);
        //     elem["children"].push({ name: cname });
        //   });

        //   data["children"][el];
        //   console.log([...new Set(el["children"]["catnames"])]);
        // });
        // let prodcatnames = [...new Set(data["children"][0]["children"])];
        // console.log(prodcatnames);
        // }
      });
      console.log(datam);
      setData({ ...data, data: datam });
    });
  }, []);

  return (
    <div className="sunburst">
      <Sunburst
        data={data.data}
        scale="exponential"
        tooltipContent={
          <div
            class="sunburstTooltip"
            style="position:absolute; color:black; z-index:10; background: #FFF; padding: 2px; text-align: center;"
          />
        }
        tooltip
        tooltipPosition="right"
        keyId="Sunburst"
        width={window.innerWidth * 0.9}
        value={data.criteria}
        height={window.innerHeight * 0.9}
        styles={styles}
      />
    </div>
  );
};

export default SunburstData;
